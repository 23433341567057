import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Yummy Technologies - Agence web et cloud à Namur</title>
                <meta name="description" content="Yummy Technologies - Une agence de création de site web et d'application mobile à Namur." />
                <meta name="og:title" property="og:title" content="Yummy Technologies - Une agence web à Namur pour développer vos projets"></meta>
                <meta name="twitter:card" content="Yummy Technologies - Une agence web à Namur pour développer vos projets"></meta>
                <link rel="canonical" href="https://yummy-technologies.be/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
